import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS , HttpClientModule } from '@angular/common/http';


import { AppComponent } from './app.component';
import { HeadComponent } from './head/head.component';
import { IndexComponent } from './index/index.component';
import { SeachComponent } from './seach/seach.component';
import { SeachsComponent } from './seachs/seachs.component';
import { ContectComponent } from './contect/contect.component';
import { FootComponent } from './foot/foot.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { NgxEchartsModule } from 'ngx-echarts';
import {FileUploadModule} from 'ng2-file-upload';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TutotialComponent } from './tutotial/tutotial.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadComponent,
    IndexComponent,
    SeachComponent,
    SeachsComponent,
    ContectComponent,
    FootComponent,
    LayoutComponent,
    TutotialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxEchartsModule,
    HttpClientModule,
    FileUploadModule,
    BsDropdownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
