import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { HeadComponent } from './head/head.component';
import { FootComponent } from './foot/foot.component';
import { ContectComponent } from './contect/contect.component';
import { SeachComponent } from './seach/seach.component';
import { SeachsComponent } from './seachs/seachs.component';
import { LayoutComponent } from './layout/layout.component';
import {TutotialComponent} from './tutotial/tutotial.component';

// 配置路由器表
const routes: Routes = [
  {
    path : '',
    redirectTo: '/CASAVA',
    pathMatch: 'full'
  },
  {
    path : 'head',
    component : HeadComponent
  },
  {
    path : 'foot',
    component : FootComponent
  },
  {
    path : 'CASAVA',
    component : LayoutComponent,
    children : [
      {
        path : '',
        component : IndexComponent
      },
      {
        path : 'seach',
        component : SeachComponent
      },
      {
        path : 'seachs',
        component : SeachsComponent
      },
      {
        path : 'download',
        component : ContectComponent
      },
      {
        path : 'tutorial',
        component : TutotialComponent
      }
    ]
  },
];
// 配置路由出口及路由导航链接
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports : [RouterModule]
})
export class AppRoutingModule { }
