import {Component , OnInit} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import { HttpClient , HttpHeaders } from '@angular/common/http';

import * as echarts from 'echarts';
import {Config} from '../seachs/seachs.component';




@Component({
  selector: 'app-seach',
  templateUrl: './seach.component.html',
  styleUrls: ['./seach.component.css']
})
export class SeachComponent implements OnInit {
  firstname: boolean[];
  uploader: FileUploader ;

  options: any;
  config: Config;
  showNum: boolean;
  strs: string;
  selectallflag: boolean;
  backmessage: string;

  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.showConfig();
    this.firstname = [];
    this.selectallflag = true;
    for ( let i = 0 ; i < 24; i++) {
      this.firstname.push(true);
    }
    this.showNum = false;
    this.backmessage = '';
    this.uploader = new FileUploader({
      url: '/api/file',
      method: 'POST',
      itemAlias: 'file',
      removeAfterUpload: true,
      autoUpload: false,
    });
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
  }

  selects(e) {
    if (e.target.files[0].size >= 1024 * 10) {
      window.alert('File over limit');
      this.uploader.clearQueue();
      window.document.getElementById('fi').setAttribute('value', '2');
    }
  }

  useFileorStrs() {
    for (let i = 0 ; i < this.firstname.length ; i++) {
      this.backmessage = this.backmessage + String(this.firstname[i] + ',');
    }
    const myEc = echarts as any;
    const div2 = document.getElementById('ed');
    window.document.getElementById('pic1').setAttribute('hidden', 'hidden');
    div2.removeAttribute('hidden');
    const lineChart2 = myEc.init(div2);
    lineChart2.showLoading({
      text: 'loading',
      bottom: '7%',
      color: '#4cbbff',
      textColor: '#4cbbff',
      maskColor: 'rgba(0, 0, 0, 0)',
    });
    if (this.uploader.queue.length > 0 ) {
      this.uploadFile();
    } else if (this.uploader.queue.length === 0 ) {
      if (this.strs.length > 3 ) {
        this.uploadStrs();
      }
    }
    this.backmessage = '';
  }
  uploadFile() {

    this.uploader.options.headers = [
      { name: '1234' , value: this.backmessage}
    ]
    // 上传
    this.uploader.queue[0].onSuccess = (response: any, status, headers) => {
      // 上传文件成功
      if (status === 200) {
        // 上传文件后获取服务器返回的数据
        const res = JSON.parse(response);
        const mabu = res.chrs;
        const datalast: number[][] = [];
        for (const entry of mabu) {
          const dt2: number[] = [];
          for (const entry1 of entry) {
            dt2.push(Number(entry1));
          }
          datalast.push(dt2);
        }
        const yLable = res.ylable;
        const fiout = res.fileout;
        let fioutt = '';
        for (let i = 0 ; i < fiout.length; i ++) {
          fioutt = fioutt + yLable[i] + ',' + fiout[i] + '\n';
        }
        this.show(datalast, yLable, fioutt);
        this.getnowfileloder();
      } else {
        // 上传文件后获取服务器返回的数据错误
        this.uploader.clearQueue();
      }
    };
    // onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any;
    // 初始化echarts图标,载入动画
    this.uploader.queue[0].upload(); // 开始上传

  }

  uploadStrs() {
    const head = new HttpHeaders().set( '1234', this.backmessage);
    this.http.post('/api/strs',  this.strs, {headers : head})
      .toPromise()
      .then((data: any) => {
        const mabu = data.chrs;
        const datalast: number[][] = [];
        for (const entry of mabu) {
          const dt2: number[] = [];
          for (const entry1 of entry) {
            dt2.push(Number(entry1));
          }
          datalast.push(dt2);
        }
        const yLable = data.ylable;
        const fiout = data.fileout;
        let fioutt = '';
        for (let i = 0 ; i < fiout.length; i ++) {
          fioutt = fioutt + yLable[i] + ',' + fiout[i] + '\n';
        }
        const myEc = echarts as any;
        const div2 = document.getElementById('ed');
        window.document.getElementById('pic1').setAttribute('hidden', 'hidden');
        div2.removeAttribute('hidden');
        const lineChart2 = myEc.init(div2);
        lineChart2.showLoading({
          text: 'loading',
          bottom: '7%',
          color: '#4cbbff',
          textColor: '#4cbbff',
          maskColor: 'rgba(0, 0, 0, 0)',
        });
        this.show(datalast, yLable, fioutt);
      });
  }
  showConfig() {
    this.http.get<Config>('assets/config.json')
      .subscribe(
        (data: Config) => this.config = {...data}, // success path
      );
  }
  show(datas, yLable, out) {
    // 初始化echarts的工厂
    const ec = echarts as any;
    const div = window.document.getElementById('pic1');
    div.removeAttribute('hidden');
    let heigth = 0;
    if (yLable.length < 10) {
      heigth = yLable.length * 25 + 200;
    } else {
      heigth = yLable.length * 30 ;
    }
    const newdiv = window.document.createElement('div');
    newdiv.setAttribute('id', 'pic2');
    newdiv.style.height = heigth + 'px';
    newdiv.style.width = '70%';
    newdiv.style.marginLeft = '14%';
    if (div.hasChildNodes()) {
      div.removeChild(div.childNodes.item(0));
    }
    div.appendChild(newdiv);
    const lineChart = ec.init(newdiv, null , devicePixelRatio = 10);
    // 得到查询数据和名字
    const title = this.config.title;
    const title2 = [];
    for (let i = 0; i < title.length; i++) {
      if (this.firstname[i]) {
        title2.push(title[i]);
      }
    }
    // 设置options
    this.options = {
      toolbox: {
        left: 'right',
        itemGap: 20,
        show: true,
        itemSize: 25,
        Left: '0%',
        feature: {
          myButton2: {
            show: true,
            title: 'show num',
            icon: 'image:///assets/images/num.png',
            option: {
            },
            onclick : function() {
              this.showNum = !this.showNum;
              this.options = {
                series: [{
                  label: {
                    normal: {
                      fontSize: 9,
                      show: this.showNum,
                    }
                  }
                }]
              };
              lineChart.setOption(this.options);
            }
          },
          saveAsImage: {
            show: true,
            title: 'Download pic'
          },
          myButtons: {
            show: true,
            title: 'Download csv',
            icon: 'image:///assets/images/dn.jpeg',
            option: {},
            onclick : function() {
              const pom = document.createElement('a');
              pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent('Chr:Location,' + title2 + '\n' + out));
              pom.setAttribute('download', 'Result.csv');
              if (document.createEvent) {
                const event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                pom.dispatchEvent(event);
              } else {
                pom.click();
              }
            }
          },
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '7%',
        containLabel: true
      },
      xAxis: [
        {
          nameTextStyle: {
            fontSize : 10
          },
          position: 'top',
          type: 'category',
          data: title2,
          silent: false,
          splitLine: {
            show: false
          },
          axisLabel: {
            interval: 'auto',
            rotate: -45
          },
        }
      ],
      yAxis: [
        {
          data: yLable,
          type: 'category',
        }
      ],
      visualMap: {
        min: 0,
        max: 1,
        calculable: true,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        top: '3%',
        inRange: {color: [ '#00EAFF', '#3C8CE7']}
      },
      series: [{
        name: 'Punch Card',
        type: 'heatmap',
        label: {
          normal: {
            show: this.showNum,
          }
        },
        data: datas
      }]
    };
    const op = this.options;
    document.getElementById('ed').setAttribute('hidden', 'hidden');
    lineChart.setOption(op);
    const ck = document.getElementById('ckdn');
  }

  selectAll() {
    if (this.selectallflag) {
      for ( let i = 0 ; i < this.firstname.length ; i++) {
        this.firstname[i] = false;
      }
      this.selectallflag = false;
    } else {
      for ( let i = 0 ; i < this.firstname.length; i++) {
        this.firstname[i] = true;
      }
      this.selectallflag = true;
    }
  }
  selectSingle() {
    this.selectallflag = false;
  }
  getnowfileloder() {
    this.uploader.clearQueue();
    this.uploader = new FileUploader({
      url: '/api/file',
      method: 'POST',
      itemAlias: 'file',
      removeAfterUpload: true,
      autoUpload: false,
    });
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
  }
}
