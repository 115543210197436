import {Component , OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as echarts from 'echarts';


export class Config {
  title: string[];
}
@Component({
  selector: 'app-seachs',
  templateUrl: './seachs.component.html',
  providers: [],
  styleUrls: ['./seachs.component.css']
})
export class SeachsComponent implements OnInit {
  SeachForm = {
    chr: [],
    location: []
  };
  error: any;
  headers: string[];
  trNum: number;
  options: any;
  config: Config;
  checklen: Config;
  options2: any;
  erromessage: string[];
  firstname: boolean[];
  selectallflag: boolean;
  backmessage: string;
  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.showConfig();
    this.trNum = 2;
    this.GenerateSelectLabel();
    this.firstname = [];
    this.selectallflag = true;
    for ( let i = 0 ; i < 24; i++) {
      this.firstname.push(true);
    }
    this.backmessage = '';

  }
  onsubmit() {
    const re = this.CheckInput();
    if ( re === 1 ) {
      return;
    }
    for (let i = 0;  i < 5; i++) {
      const tr = window.document.getElementById('pic' + (i + 1));
      tr.setAttribute('hidden', 'hidden');
    }
    const myEc = echarts as any;
    const div2 = document.getElementById('load');
    div2.removeAttribute('hidden');
    const lineChart2 = myEc.init(div2);
    lineChart2.showLoading({
      text: 'loading',
      bottom: '7%',
      color: '#4cbbff',
      textColor: '#4cbbff',
      maskColor: 'rgba(0, 0, 0, 0)',
    });
    const formData = {
      chrs: [ this.SeachForm.chr, this.SeachForm.location]
    };
    for (let i = 0 ; i < this.firstname.length ; i++) {
      this.backmessage = this.backmessage + String(this.firstname[i] + ',');
    }
    const head = new HttpHeaders().set( '1234', this.backmessage);
    this.http.post('/api/all', formData, { headers : head})
      .toPromise()
      .then((data: any) => {
        div2.setAttribute('hidden', 'hidden');
        this.show(data.chrs);
      });
    this.backmessage = '';
  }
  show(datas) {
    const ec = echarts as any;
    const reda = [];
    const reti = [];
    for (let i = 0; datas.length > i; i++) {
      window.document.getElementById('pic' + (i + 1)).removeAttribute('hidden');
      const titls = this.config.title;
      const lin = datas[i];
      const da = [];
      const ti = [];
      for (let j = 0; j < lin.length ; j++) {
        let flage = 0;
        let sign = 0;
        for (let k = 0; k < lin.length ; k++) {
          if (flage < lin[k]) {
            sign = k;
            flage = lin[k];
          }
        }
        da.push(flage);
        ti.push(titls[sign]);
        lin[sign] = 0;
      }
      reda.push(da);
      reti.push(ti);
    }
    const title = this.config.title;
    const title2 = [];
    for (let i = 0; i < title.length; i++) {
      if (this.firstname[i]) {
        title2.push(title[i]);
      }
    }
    this.options = {
      title: {
        x: 'auto',
        y: 20,
        textStyle: {
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
      },
      color: ['#3458DB'],
      toolbox: {
        show: true,
        itemSize: 30,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download pic'
          }
        }
      },
      grid: {
        left: '15%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis : [
        {
          type : 'category',
          nameTextStyle: {
            fontSize: 8
          },
          data : ['这', '里', '应', '该', '是', '有', '二', '十', '四' , '个' , 'c' , 'h' , 'r' ,
            '的' , '名' , '字', '组', '成' , '的' , '一' , '个' , '坐' , '标' , '系'],
          silent: false,
          splitLine: {
            show: false
          },
          axisLabel: {
            interval: 'auto',
            rotate: 40,
            fontSize: 10
          },
          axisTick: {
            alignWithLabel: true
          },
        }
      ],
      yAxis : [
        {
          name: 'CASAVA score',
          nameGap: 50,
          nameTextStyle: {
            fontSize: 12,
          },
          nameLocation: 'center',
          type : 'value',
          splitLine: {
            show: false
          },
        }
      ],
      series : [
        {
          name: 'number',
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            normal: {
              color: function(params) {
                // build a color map as your need.
                const colorList = ['#d53e50', '#a6a6a6'];
                if (params.data > 0.5) {
                  return colorList[0];
                } else {
                  return colorList[1];
                }
              }
            }
          },
          data: reda[0]
        }
      ]
    };
    const charts = [];
    for (let i = 0; datas.length > i; i++) {
      const divv = window.document.getElementById('pic' + (i + 1));
      const lineChart =  ec.init(window.document.getElementById('pic' + (i + 1)), null , devicePixelRatio = 10);
      lineChart.setOption(this.options);
      lineChart.setOption({
        title: {
          x: '52%',
          text: 'Chr' + this.SeachForm.chr[i] + ': ' + this.SeachForm.location[i],
        },
        xAxis : [
          {
            data : title2,
          }
        ],
        series : [
          {
            data: reda[i]
          }
        ]
      });
      charts.push(lineChart);
    }
    this.clearDate();
  }
  addSeach() {
    if (this.trNum < 6) {
      const tr = window.document.getElementById('tr' + this.trNum);
      tr.removeAttribute('hidden');
      this.trNum = this.trNum + 1;
    } else {
      window.alert('A single query allows up to five sets of input');
    }
  }
  removeSeach() {
    if (this.trNum > 2) {
      const tr = window.document.getElementById('tr' + (this.trNum - 1));
      tr.setAttribute('hidden', 'hidden');
      this.trNum = this.trNum - 1;
    } else {
      window.alert('At least one set of inputs');
    }

  }
  getConfig() {
    // now returns an Observable of Config
    return this.http.get<Config>('assets/config.json');
  }
  showConfig() {
    this.getConfig()
      .subscribe(
        (data: Config) => this.config = { ...data }, // success path
        error => this.error = error
      );
    this.http.get<Config>('assets/demo.json')
      .subscribe(
        (data: Config) => this.checklen = { ...data }, // success path
        error => this.error = error
      );
  }
  clearDate() {
    for (let i = 2; i < 6; i++) {
      window.document.getElementById('tr' + (i)).setAttribute('hidden', 'hidden');
    }
    this.SeachForm.location =  [];
    this.SeachForm.chr = [];
    this.trNum = 2;
  }
  GenerateSelectLabel() {
    for (let i = 1; i < 6; i++) {
      const sl = window.document.getElementById('s' + (i));
      for (let j = 1; j < 23; j++) {
        const op = window.document.createElement('option');
        op.text = 'chr' + j;
        op.value = j.toString();
        sl.appendChild(op);
      }
      const opx = window.document.createElement('option');
      opx.text = 'chrX';
      opx.value = 'x';
      sl.appendChild(opx);
    }
  }
  CheckInput() {
    const imfo = [];
    this.erromessage = [];
    const lo = this.SeachForm.location;
    for (let i = 0; i < lo.length; i++) {
      const zhuan = Number(lo[i]);
      if (isNaN(zhuan)) {
        imfo.push('all input location must be number\n');
      } else {
        const ch = this.SeachForm.chr[i];
        const chnum = Number(ch);
        let maxindex = 0;
        if (isNaN(chnum)) {
          maxindex = Number(this.checklen.title[22]);
        } else {
          maxindex = Number(this.checklen.title[ch - 1]);
        }
        if (zhuan > maxindex) {
          imfo.push('The' + (i + 1) + 'location over the maximum \n');
        }
        if ( zhuan < 0 ) {
          imfo.push('The location cannot be negative\n');
        }
      }
    }
    if (imfo.length > 0) {
      this.erromessage = imfo;
      return 1;
    }
  }
  selectAll() {
    if (this.selectallflag) {
      for ( let i = 0 ; i < this.firstname.length ; i++) {
        this.firstname[i] = false;
      }
      this.selectallflag = false;
    } else {
      for ( let i = 0 ; i < this.firstname.length; i++) {
        this.firstname[i] = true;
      }
      this.selectallflag = true;
    }
  }
  selectSingle() {
    this.selectallflag = false;
  }
}

