import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-tutotial',
  templateUrl: './tutotial.component.html',
  styleUrls: ['./tutotial.component.css']
})
export class TutotialComponent implements OnInit {

  @ViewChild('distannce1') distannce1: ElementRef;
  @ViewChild('distannce2') distannce2: ElementRef;
  @ViewChild('distannce3') distannce3: ElementRef;
  @ViewChild('distannce4') distannce4: ElementRef;

  constructor() { }

  ngOnInit() {
    const cont = window.document.getElementById('ex');
    cont.innerHTML = 'chr3  19030004\n' +
      'chr4  174094940\n' +
      'chr2  170776521\n' +
      'chr19  36642884\n' +
      'chr14  83347928\n' +
      'chr2  22729205\n' +
      'chr16  20553483\n';
  }

  goDistance1() {
    this.distannce1.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  goDistance2() {
    this.distannce2.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  goDistance3() {
    this.distannce3.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
}
